import * as React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Layout from "./components/Layout";
import Login from "./pages/auth/login/Login";
import Reset_password from "./pages/auth/reset-password/Reset_password";
import "./style.scss";
import Dashboard from "./pages/dashbaord/Dashboard";
import Users from "./pages/dashbaord/users/Users";
import Create_user from "./pages/dashbaord/users/Create_user";
import Edit_user from "./pages/dashbaord/users/Edit_user";
import Config from "./pages/dashbaord/config/Config";
import Create_farm_stage from "./pages/dashbaord/config/farming stage/Create_farm_stage";
import Create_incidence from "./pages/dashbaord/config/incidence/Create_incidence";
import Create_crop from "./pages/dashbaord/config/crop/Create_crop";
import Create_association from "./pages/dashbaord/config/association/Create_association";
import All_Seasons from "./pages/dashbaord/season/Seasons";
import Create_season from "./pages/dashbaord/season/Create_season";
import Manage_season from "./pages/dashbaord/season/manage season/Manage_season";
import All_states from "./pages/dashbaord/season/manage season/states/All_states";
import Create_state from "./pages/dashbaord/season/manage season/states/Create_state";
import View_more from "./pages/dashbaord/season/manage season/states/View_more";
import EOP from "./pages/dashbaord/season/EOP/EOP";
import Create_eop from "./pages/dashbaord/season/EOP/Create_eop";
import { Provider as AlertProvider } from "react-alert";
import { AlertTemplate } from "./components/AlertTemplate";
// import { AuthProvider } from "./Context/AuthContext";
import {
  ExternalRoute,
  InternalRoute,
  SpecialRoute,
} from "./components/Auth.route";
import { Provider } from "react-redux";
import store from "./redux/store";
import { AuthProvider } from "./context/authContext";
import Edit_farm_stage from "./pages/dashbaord/config/farming stage/Edit_farm_stage";
import Edit_incidence from "./pages/dashbaord/config/incidence/Edit_incidence";
import Edit_crop from "./pages/dashbaord/config/crop/Edit_crop";
import Edit_association from "./pages/dashbaord/config/association/Edit_association";
import Edit_state from "./pages/dashbaord/season/manage season/states/Edit_state";
import Edit_season from "./pages/dashbaord/season/Edit_season";
import Upload_cluster from "./pages/dashbaord/season/manage season/upload/Upload_cluster";
import View_eop from "./pages/dashbaord/season/EOP/View_eop";
import Edit_eop from "./pages/dashbaord/season/EOP/Edit_eop";
import "react-datepicker/dist/react-datepicker.css";
import All_eop from "./pages/dashbaord/season/EOP/All_eop";
import Create_yield from "./pages/dashbaord/season/EOP/expect_yield/Create_yield";
import Edit_yield from "./pages/dashbaord/season/EOP/expect_yield/Edit_yield";
import Forget_password from "./pages/auth/reset-password/Forget_Password";
import Verify_Email from "./pages/auth/reset-password/Verify_password";
import All_upload from "./pages/dashbaord/season/manage season/upload/All_upload";
import Season_form from "./pages/dashbaord/season/manage season/season_form/season_form";
import Create_season_form from "./pages/dashbaord/season/manage season/season_form/Create_crop_calender";
import Edit_season_form from "./pages/dashbaord/season/manage season/season_form/Edit_season_form";
import Duplicate_season_form from "./pages/dashbaord/season/manage season/season_form/Duplicate_season_form";
import View_farm_stage from "./pages/dashbaord/config/farming stage/View_farm_stage";
import Duplicate_farm_stage from "./pages/dashbaord/config/farming stage/Duplicate_farm_stage";
import Create_crop_calender from "./pages/dashbaord/season/manage season/season_form/Create_crop_calender";
import { roless } from "./Constants";
import Season_form_answer from "./pages/dashbaord/season/manage season/season_form/season_form_answer";
import Upload_template from "./pages/dashbaord/season/manage season/upload/Upload_template";
import Upload_werehouse from "./pages/dashbaord/season/manage season/upload/upload_werehouse";
import UpdateCluster from "./pages/dashbaord/season/manage season/upload/UpdateCluster";

const App = () => {
  return (
    <React.Fragment>
      <Provider store={store}>
        <AlertProvider
          template={AlertTemplate}
          timeout={5000}
          position={"bottom right"}
        >
          <Router>
            <AuthProvider>
              <Switch>
                <ExternalRoute exact path="/" component={Login} />
                <ExternalRoute
                  path="/reset-password/:id"
                  component={Reset_password}
                />
                <ExternalRoute
                  path="/verify-email/:id"
                  component={Verify_Email}
                />
                <ExternalRoute
                  path="/forget-password"
                  component={Forget_password}
                />

                <InternalRoute>
                  {/* <Layout/> */}
                  <SpecialRoute
                    path="/dashboard"
                    isPrivate
                    roles={[
                      roless.Assistant,
                      roless.BusinessAd,
                      roless.Crop,
                      roless.Field,
                      roless.ITAd,
                      roless.National,
                      roless.Report,
                      roless.State,
                      roless.WarehouseManager,
                    ]}
                    component={Dashboard}
                  />
                  <SpecialRoute path="/users" isPrivate component={Users} />
                  <SpecialRoute
                    path="/create-user"
                    isPrivate
                    component={Create_user}
                  />
                  <SpecialRoute
                    path="/edit-user/:id"
                    isPrivate
                    component={Edit_user}
                  />
                  <SpecialRoute path="/config" isPrivate component={Config} />
                  <SpecialRoute
                    path="/create-farm-stage"
                    isPrivate
                    component={Create_farm_stage}
                  />
                  <SpecialRoute
                    path="/edit-farm-stage/:id"
                    isPrivate
                    component={Edit_farm_stage}
                  />

                  <SpecialRoute
                    path="/duplicate-farm-stage/:id"
                    isPrivate
                    component={Duplicate_farm_stage}
                  />

                  <SpecialRoute
                    path="/view-farm-stage-question/:id"
                    isPrivate
                    component={View_farm_stage}
                  />
                  <SpecialRoute
                    path="/create-incidence"
                    isPrivate
                    component={Create_incidence}
                  />
                  <SpecialRoute
                    path="/edit-incidence/:id"
                    isPrivate
                    component={Edit_incidence}
                  />
                  <SpecialRoute
                    path="/create-crop"
                    isPrivate
                    component={Create_crop}
                  />
                  <SpecialRoute
                    path="/edit-crop/:id"
                    isPrivate
                    component={Edit_crop}
                  />
                  <SpecialRoute
                    path="/create-association"
                    isPrivate
                    component={Create_association}
                  />
                  <SpecialRoute
                    path="/edit-association/:id"
                    isPrivate
                    component={Edit_association}
                  />
                  <SpecialRoute
                    path="/season"
                    isPrivate
                    component={All_Seasons}
                  />
                  <SpecialRoute
                    path="/create-season"
                    isPrivate
                    component={Create_season}
                  />
                  <SpecialRoute
                    path="/edit-season/:id"
                    isPrivate
                    component={Edit_season}
                  />
                  <SpecialRoute
                    path="/manage-season/:id"
                    isPrivate
                    component={Manage_season}
                  />
                  <SpecialRoute
                    path="/upload-cluster/:id"
                    isPrivate
                    component={Upload_cluster}
                  />
                  <SpecialRoute
                    path="/upload-template"
                    isPrivate
                    component={Upload_template}
                  />
                  <SpecialRoute
                    path="/upload-werehouse/:id"
                    isPrivate
                    component={Upload_werehouse}
                  />
                  <SpecialRoute
                    path="/update-cluster/:uniqueId"
                    isPrivate
                    component={UpdateCluster}
                  />

                  <SpecialRoute
                    path="/all-upload/:id"
                    isPrivate
                    component={All_upload}
                  />
                  <SpecialRoute
                    path="/season-form/:id"
                    isPrivate
                    component={Season_form}
                  />
                  <SpecialRoute
                    path="/season-form-answer/:id"
                    isPrivate
                    component={Season_form_answer}
                  />
                  <SpecialRoute
                    path="/create-crop-calender/:id"
                    isPrivate
                    component={Create_crop_calender}
                  />
                  <SpecialRoute
                    path="/edit-season-form/:seasonId/:farmStageId"
                    isPrivate
                    component={Edit_season_form}
                  />

                  <SpecialRoute
                    path="/duplicate-season-form/:seasonId/:farmStageId"
                    isPrivate
                    component={Duplicate_season_form}
                  />

                  <SpecialRoute
                    path="/states/:id"
                    isPrivate
                    component={All_states}
                  />
                  <SpecialRoute
                    path="/create-states/:id"
                    isPrivate
                    component={Create_state}
                  />
                  <SpecialRoute
                    path="/edit-states/:id/:stateId"
                    isPrivate
                    component={Edit_state}
                  />
                  <SpecialRoute
                    path="/view-more/:id/:stateId"
                    isPrivate
                    component={View_more}
                  />
                  <SpecialRoute path="/eop/:id" isPrivate component={EOP} />
                  <SpecialRoute
                    path="/create-eop/:id"
                    isPrivate
                    component={Create_eop}
                  />
                  <SpecialRoute
                    path="/view-eop/:id/:in"
                    isPrivate
                    component={View_eop}
                  />
                  <SpecialRoute
                    path="/edit-eop/:id"
                    isPrivate
                    component={Edit_eop}
                  />
                  <SpecialRoute
                    path="/all-eop/:id"
                    isPrivate
                    component={All_eop}
                  />
                  <SpecialRoute
                    path="/create-yield/:id"
                    isPrivate
                    component={Create_yield}
                  />
                  <SpecialRoute
                    path="/edit-yield/:id"
                    isPrivate
                    component={Edit_yield}
                  />
                  {/* </Layout> */}
                </InternalRoute>
              </Switch>
            </AuthProvider>
          </Router>
        </AlertProvider>
      </Provider>
    </React.Fragment>
  );
};
export default App;
