import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardText,
  Col,
  Container,
  Input,
  Row,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  
  UncontrolledDropdown,
  FormGroup,
  Label,
} from "reactstrap";
import AppBreadcrumb from "../../../../components/layout/breadcrumb/Breadcrumb";
import chart from "../../../../assets/img/charts.png";
import { useLocation, RouteComponentProps } from "react-router-dom";
import { saveAs } from "file-saver";
import { seasonService } from "../../../../Services";
import { useLoadingMessage } from "../../../../components/useLoadingMessage";
import { PageBody } from "../../../../components/Loader";
import CountTo from "react-count-to";
import Layout from "../../../../components/Layout";
import Currency from "../../../../components/Currency";
import { roless } from "../../../../Constants";
import { AuthContext } from "../../../../context/authContext";
import classnames from "classnames";
import { AnyMessageParams } from "yup/lib/types";
import { HiDotsVertical } from "react-icons/hi";
import Select from "react-select";
import { ErrorMessage } from "formik";
import { useAlert } from "react-alert";

const Manage_season = ({ match }: RouteComponentProps<{ id: string }>) => {
  const { isLoading, message, updateLoader } = useLoadingMessage();
  const [clusters, setClusters] = React.useState<any>([]);
  const location = useLocation();
  const { seasonData } = location.state;
  const { role } = React.useContext(AuthContext);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  const [templateDropdownOpen, setTemlateDropdownOpen] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState("1");
  const [currentActiveTab, setCurrentActiveTab] = React.useState("1");
  const [warehouse, setWarehouse] = React.useState<any>([]);
  const [search, setSearch] = React.useState("");
  const [warehousename, setWarehouseName] = React.useState<{ label: string; value: string }[]>([]);
  const [selectedClusterUniqueId, setSelectedClusteruniqueId] = React.useState<any>([]);
  const [selectedWarehouseId, setSelectedWarehouseId] = React.useState<string | null>(null);

  const alert = useAlert();

  const toggle = (tab: React.SetStateAction<string>) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };

  const toggleTemplateDropdown = () =>
    setTemlateDropdownOpen((prevState) => !prevState);

const handleWarehouseChange = (selectedOption: { value: string; label: string } | null) => {
  setSelectedWarehouseId(selectedOption ? selectedOption.value : null);
};
 
  const getclusters = async (query: number) => {
    updateLoader(true, "Fetching Data");
    const fields = await seasonService.getAllField(match.params.id);

    const bd = {
      pageNumber: query,
      pageSize: 20,
      seasonId: match.params.id,
      clusterColumns: [...fields.data.data],
    };
    const response = await seasonService.getAllCluster(bd);
    setClusters(response.data.data);
    updateLoader(false, "");
  };

  const getWerehouse = async () => {
    updateLoader(true, "Fetching Data");
    const fields = await seasonService.getAllField(match.params.id);
    console.log("Field", fields)
    const response = await seasonService.getAllWerehouse();
    console.log("Response", response);
    setWarehouse(response.data.data.warehouseData);
    updateLoader(false, "");
  };



  React.useEffect(() => {
    const getWarehouses = async () => {
      try {
        updateLoader(true, "Fetching Data");
        const response = await seasonService.getAllWerehouse();
        console.log("Response", response);
        
        const formattedWarehouses = response.data.data.warehouseData.map((warehouse: any) => ({
          label: warehouse['werehouse name'],
          value: warehouse['uniqueId'],
        
        }));
        
        setWarehouseName(formattedWarehouses);
      } catch (error) {
        console.error("Error fetching warehouses:", error);
      } finally {
        updateLoader(false, "");
      }
    };

    getWarehouses();
  }, []);


  const handleCheckboxChange = (uniqueId: string) => {
  setSelectedClusteruniqueId((prevState: string[]) => {
    
    const isAlreadySelected = prevState.includes(uniqueId);
   
    const newSelection = isAlreadySelected
      ? prevState.filter(id => id !== uniqueId) 
      : [...prevState, uniqueId];  

      // Log the selected unique IDs
      console.log("Selected Cluster Unique IDs:", newSelection);

      return newSelection;
    });
  };

  const linkWarehouse = async (warehouseId: string) => {
    if (!selectedClusterUniqueId.length) {
      alert.error("Please select clusters.");
      return;
    }

    if (!warehouseId) {
      alert.error("Please select a warehouse.");
      return;
    }

    try {
      const response = await seasonService.linkWarehouse(
        warehouseId,
        selectedClusterUniqueId
      );
      if (response.status === 200) {
        getclusters(1);
        alert.success(response.data.message);
        console.log("Successfully linked clusters to warehouse:", response.data.message);
      } else {
        alert.error("Failed to link warehouse");
      }
    } catch (error: any) {
      console.error("Error linking clusters to warehouse:", error);
      alert.error(error.response.data.message);
    }
  };

  const clusterss =
    clusters?.clusterData?.data &&
    clusters?.clusterData?.data?.filter((farmingS: any) => {
      // console.log(farmingS.ClusterState.toLowerCase())
      return (
        farmingS?.ClusterState.toLowerCase().indexOf(search.toLowerCase()) !==
          -1 ||
        farmingS?.clusterlga.toLowerCase().indexOf(search.toLowerCase()) !==
          -1 ||
        farmingS?.clustername.toLowerCase().indexOf(search.toLowerCase()) !==
          -1 ||
        farmingS?.numberofhectares
          .toLowerCase()
          .indexOf(search.toLowerCase()) !== -1 ||
        farmingS?.clusterfarmlocation
          .toLowerCase()
          .indexOf(search.toLowerCase()) !== -1
      );
    });

  const Warehouses = warehouse?.warehouseData;
  warehouse?.warehouseData?.filter((warehou: any) => {
    return (
      warehou.lga.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
      warehou.address.toLowerCase().indexOf(search.toLowerCase()) !== -1
    );
  });

  React.useEffect(() => {
    getclusters(1);
    getWerehouse();
  }, []);
  return (
    <PageBody isLoading={isLoading} message={message}>
      <Layout active="Seasons">
        <Container fluid>
          <h5>
            Season: {seasonData.seasonType + " " + seasonData.associationName}
          </h5>

          <Row>
            <Col>
              <div className="d-flex align-items-center">
                {/* <Button
                className="btn-download px-4 py-2 shadow bg-or mr-5"
                inline
                onClick={async () => {
                  const response: any = await seasonService.downloadCluster();
                  saveAs(response, "Template.csv");
                }}
              >
                Download Template
              </Button> */}
                {/* <Link to={`/upload-cluster/${match.params.id}`}>
              <Button className="btn-create px-4 py-2 shadow mr-5" inline>
                Upload Cluster
              </Button>
            </Link> */}
                <Link
                  to={{
                    pathname: `/all-upload/${match.params.id}`,
                    state: {
                      seasonData: seasonData,
                    },
                  }}
                >
                  <Button className="btn-create px-4 py-2 shadow mr-5" inline>
                    Upload Template
                  </Button>
                </Link>
                <Dropdown
                  isOpen={templateDropdownOpen}
                  toggle={toggleTemplateDropdown}
                >
                  <DropdownToggle
                    caret
                    className="btn-create px-4 py-2 shadow mr-5"
                  >
                    Download Template
                  </DropdownToggle>
                  <DropdownMenu
                    style={{ borderRadius: "10px", padding: "10px" }}
                  >
                    {roless.BusinessAd === role || roless.ITAd === role ? (
                      <>
                        <DropdownItem tag="div" className="text-center p-0">
                          <Button
                            className="btn-create w-100 px-4 py-2 shadow"
                            onClick={async () => {
                              const response =
                                await seasonService.downloadCluster();
                              saveAs(response, "Cluster_Template.csv");
                            }}
                          >
                            Download Cluster Template
                          </Button>
                        </DropdownItem>
                        <DropdownItem
                          tag="div"
                          className="text-center p-0 mt-2"
                        >
                          <Button
                            className="btn-create w-100 px-4 py-2 shadow"
                            onClick={async () => {
                              const response =
                                await seasonService.downloadWehehouse();
                              saveAs(response, "Warehouse_Template.csv");
                            }}
                          >
                            Download Warehouse Template
                          </Button>
                        </DropdownItem>
                      </>
                    ) : null}
                  </DropdownMenu>
                </Dropdown>
                <Link
                  to={{
                    pathname: `/eop/${match.params.id}`,
                    state: {
                      status: seasonData.isActive,
                      seasonData: seasonData,
                    },
                  }}
                >
                  <Button
                    className="btn-download px-4 py-2 shadow bg-or mr-5"
                    inline
                  >
                    EOP
                  </Button>
                </Link>{" "}
                <Link
                  to={{
                    pathname: `/season-form/${match.params.id}`,
                    state: {
                      seasonData: seasonData,
                    },
                  }}
                >
                  <Button className="btn-create px-4 py-2 shadow mr-5" inline>
                    Season Form
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="mt-4">
          <Row>
            <Col md="4">
              <Card className="shadow border-0 bg-green-2 text-white text-center">
                <CardBody>
                  <img src={chart} className="mt-4" />
                  <CardText className="display-4 font-weight-bold mt-3">
                    {clusters.hectares}
                  </CardText>
                  <CardText className="font-weight-bold h3">Hectares</CardText>
                  <Row className="my-4 ">
                    <Col>
                      {" "}
                      <CardText> {Currency(clusters.hectares)}</CardText>{" "}
                      <CardText>Provided Hectares</CardText>{" "}
                    </Col>
                    <Col>
                      {" "}
                      <CardText>0</CardText>{" "}
                      <CardText>Verified Hectares</CardText>{" "}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card className="shadow border-0 bg-green-2 text-white text-center">
                <CardBody>
                  <img src={chart} className="mt-4" />
                  <CardText className="display-4 font-weight-bold mt-3">
                    {clusters.clusters}
                  </CardText>
                  <CardText className="font-weight-bold h3">Cluster</CardText>
                  <Row className="my-4 ">
                    <Col>
                      {" "}
                      <CardText>{clusters.clusters}</CardText>{" "}
                      <CardText>Provided Cluster</CardText>{" "}
                    </Col>
                    <Col>
                      {" "}
                      <CardText>0</CardText>{" "}
                      <CardText>Verified Cluster</CardText>{" "}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Row>
                <Col>
                  <Card className="border-0 shadow text-center">
                    <CardBody>
                      {" "}
                      <CardText className="h2">{clusters.states}</CardText>{" "}
                      <CardText className="font-weight-bold">States</CardText>{" "}
                      <Link
                        to={{
                          pathname: `/states/${match.params.id}`,
                          state: {
                            seasonData: seasonData,
                          },
                        }}
                      >
                        <Button
                          className="bg-green-2 rounded-pill border-0 shadow px-4"
                          size="sm"
                        >
                          Manage
                        </Button>
                      </Link>
                    </CardBody>
                  </Card>
                </Col>
              </Row>{" "}
              <Row className="mt-5">
                <Col md="6">
                  <Card className="border-0 shadow text-center">
                    <CardBody>
                      {" "}
                      <CardText className="h2 pt-4">2</CardText>{" "}
                      <CardText className="font-weight-bold pb-3">
                        Incidence
                      </CardText>{" "}
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6">
                  {" "}
                  <Card className="border-0 shadow text-center">
                    <CardBody>
                      {" "}
                      <CardText className="h2 pt-4">
                        {clusters.warehouse}
                      </CardText>{" "}
                      <CardText className="font-weight-bold pb-3">
                        Warehouse
                      </CardText>{" "}
                    </CardBody>
                  </Card>
                </Col>
              </Row>{" "}
            </Col>
          </Row>

          <Row className="mt-4 justify-content-end">
            <Col md="auto" className="d-flex align-items-center">
              <Select
                // isMulti
                classNamePrefix="Select Warehouse"
                onChange={handleWarehouseChange}
                options={warehousename}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: "250px",
                    marginRight: "1rem",
                  }),
                }}
              />
              {selectedClusterUniqueId.length > 0 && (
                <div className="ms-4">
                  <Button
                    color="success"
                    className="btn-create px-4 py-2 shadow font-weight-bold"
                    onClick={() =>
                      selectedWarehouseId && linkWarehouse(selectedWarehouseId)
                    }
                  >
                    Link Warehouse
                  </Button>
                </div>
              )}
            </Col>
          </Row>
        </Container>

        <Container className="mt-4 mb-4">
          <Col sm={12} lg={12}>
            <div>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: currentActiveTab === "1",
                    })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Clusters
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: currentActiveTab === "2",
                    })}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Warehouse
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={currentActiveTab}>
                <TabPane tabId="1">
                  <Row>
                    <Col sm="12">
                      <Container className="mt-4">
                        <Row className="mb-4">
                          <Col md={9}>
                            <span className="ml-5">
                              <Input
                                placeholder="search"
                                type="search"
                                className="form-search"
                                name="search3"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                              />
                            </span>
                          </Col>
                          <Col md={3} className="text-right"></Col>
                        </Row>

                        <Table borderless hover responsive striped>
                          <thead>
                            <tr>
                              <th></th>
                              <th>S/N</th>
                              <th>State</th>
                              <th>L.G.A</th>
                              <th> LOCATION</th>
                              <th> CLUSTER</th>
                              <th>PFI HECTARES</th>
                              <th> WAREHOUSE</th>
                              <th>ADDRESS</th>
                            </tr>
                          </thead>
                          <tbody>
                            {clusterss && clusterss < 1 ? (
                              <div className="text-center">
                                <h5>No record found</h5>
                              </div>
                            ) : (
                              clusterss &&
                              clusterss.map((cl: any, i: number) => (
                                <tr>
                                  <td>
                                    <input
                                      type="checkbox"
                                      style={{ accentColor: "green" }}
                                      checked={selectedClusterUniqueId.includes(
                                        cl.uniqueId
                                      )}
                                      onChange={() =>
                                        handleCheckboxChange(cl.uniqueId)
                                      }
                                    />
                                  </td>
                                  <th scope="row">
                                    {(clusters.clusterData?.meta.pageNumber -
                                      1) *
                                      20 +
                                      (i + 1)}
                                  </th>
                                  <td> {cl.ClusterState} </td>
                                  <td> {cl.clusterlga} </td>
                                  <td>{cl.clusterfarmlocation}</td>
                                  <td>{cl.clustername}</td>
                                  <td> {cl.numberofhectares} </td>
                                  <td> {cl.werehousename} </td>
                                  <td>{cl.warehouseaddres}</td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </Table>

                        <div className="">
                          <Pagination aria-label="Page navigation">
                            <PaginationItem
                              disabled={
                                clusters.clusterData?.meta.pageNumber ==
                                clusters.clusterData?.meta.firstPage
                                  ? true
                                  : false
                              }
                            >
                              <PaginationLink
                                first
                                onClick={() =>
                                  getclusters(
                                    clusters.clusterData?.meta.pageNumber - 2
                                  )
                                }
                              />
                            </PaginationItem>
                            <PaginationItem
                              disabled={
                                clusters.clusterData?.meta.pageNumber ==
                                clusters.clusterData?.meta.firstPage
                                  ? true
                                  : false
                              }
                            >
                              <PaginationLink
                                previous
                                onClick={() =>
                                  getclusters(
                                    clusters.clusterData?.meta.pageNumber - 1
                                  )
                                }
                              />
                            </PaginationItem>

                            {Array.from({
                              length: clusters.clusterData?.meta.lastPage,
                            })
                              .slice(
                                clusters.clusterData?.meta.pageNumber > 5
                                  ? clusters.clusterData?.meta.pageNumber
                                  : 0,
                                clusters.clusterData?.meta.pageNumber > 5 ||
                                  clusters.clusterData?.meta.pageNumber <
                                    clusters.clusterData?.meta.lastPage - 5
                                  ? clusters.clusterData?.meta.pageNumber + 5
                                  : 5
                              )
                              .map((u: any, i: any) => (
                                <PaginationItem
                                  active={
                                    clusters.clusterData?.meta.pageNumber ==
                                    i + clusters.clusterData?.meta.pageNumber
                                      ? true
                                      : false
                                  }
                                >
                                  <PaginationLink
                                    onClick={() =>
                                      getclusters(
                                        i +
                                          clusters.clusterData?.meta.pageNumber
                                      )
                                    }
                                  >
                                    {i + clusters.clusterData?.meta.pageNumber}
                                  </PaginationLink>
                                </PaginationItem>
                              ))}
                            {clusters.clusterData?.meta.lastPage > 10 ? (
                              <PaginationItem>
                                <PaginationLink>...</PaginationLink>
                              </PaginationItem>
                            ) : null}

                            {clusters.clusterData?.meta.lastPage > 10 &&
                            clusters.clusterData?.meta.lastPage - 5 >
                              clusters.clusterData?.meta.pageNumber
                              ? Array.from({
                                  length: clusters.clusterData?.meta.lastPage,
                                })
                                  .slice(
                                    clusters.clusterData?.meta.lastPage - 5,
                                    clusters.clusterData?.meta.lastPage
                                  )
                                  .map((u: any, i: any) => (
                                    <PaginationItem
                                      active={
                                        clusters.clusterData?.meta.pageNumber ==
                                        clusters.clusterData?.meta.lastPage -
                                          5 +
                                          i
                                          ? true
                                          : false
                                      }
                                    >
                                      <PaginationLink
                                        onClick={() =>
                                          getclusters(
                                            clusters.clusterData?.meta
                                              .lastPage -
                                              5 +
                                              i
                                          )
                                        }
                                      >
                                        {clusters.clusterData?.meta.lastPage -
                                          5 +
                                          i}
                                      </PaginationLink>
                                    </PaginationItem>
                                  ))
                              : null}
                            <PaginationItem
                              disabled={
                                clusters.clusterData?.meta.pageNumber ==
                                clusters.clusterData?.meta.lastPage
                                  ? true
                                  : false
                              }
                            >
                              <PaginationLink
                                next
                                onClick={() =>
                                  getclusters(
                                    clusters.clusterData?.meta.nextPage
                                  )
                                }
                              />
                            </PaginationItem>
                            <PaginationItem
                              disabled={
                                clusters.clusterData?.meta.pageNumber ==
                                clusters.clusterData?.meta.lastPage
                                  ? true
                                  : false
                              }
                            >
                              <PaginationLink
                                last
                                onClick={() =>
                                  getclusters(
                                    clusters.clusterData?.meta.nextPage + 1
                                  )
                                }
                              />
                            </PaginationItem>
                          </Pagination>
                        </div>
                      </Container>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col sm="12">
                      <Container className="mt-4">
                        <Row>
                          <Col md={9} className="mb-5">
                            <span className="ml-5">
                              <Input
                                placeholder="search"
                                type="search"
                                className="form-search"
                                name="search"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                              />
                            </span>
                          </Col>
                          <Col md={3} className="text-right"></Col>
                        </Row>

                        <Table borderless hover responsive striped>
                          <thead>
                            <tr>
                              <th>S/N</th>
                              <th> NAME</th>
                              <th>STATE</th>
                              <th>L.G.A</th>
                              <th>ADDRESS</th>
                              {/* <th>MANAGER’S PHONE</th>

                              <th>STATUS</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {warehouse.map((item: any, index: number) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item["werehouse name"]}</td>
                                <td>{item["warehouse state"]}</td>
                                <td>{item.lga}</td>
                                <td>{item.address}</td>
                                <td></td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Container>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </div>
          </Col>
        </Container>

        {/* </Container> */}
      </Layout>
    </PageBody>
  );
};

export default Manage_season;