import {
  rolestype,
  seasontype,
  farmingStageType,
  incidenceType,
  associationType,
  cropTypeType,
  statetype,
} from "../../../models/config.model";
import { configService } from "../../../Services/configService";
import {
  seasonTypes,
  roles,
  farmingStage,
  incidence,
  association,
  cropType,
  states,
} from "../../actionTypes";
import { Dispatch } from "redux";

export class configThunk {
  static getAllSeasonType(seasons: seasontype[]) {
    return async (dispatch: Dispatch) => {
      try {
        if (seasons.length === 0) {
          const response = await configService.getAllSeasonTypes();
          console.log("HE", response.data.data);
          // const response = await UserService.getclientusers(id);
          if (response.data.data) {
            dispatch({
              type: seasonTypes.GET_ALL_SEASONS,
              payload: response.data.data,
            });
            return;
          }
        } else {
          dispatch({
            type: seasonTypes.GET_ALL_SEASONS,
            payload: seasons,
          });
          return;
        }
        throw new Error("Season Types failed");
      } catch (error) {
        // TODO: dispatch error to errorReducer
        // to be implemented
      }
    };
  }

  static getAllRoles(seasons: rolestype[]) {
    return async (dispatch: Dispatch) => {
      try {
        // if (seasons.length === 0) {
        const response = await configService.getAllRoles();
        console.log("HE", response);
        // const response = await UserService.getclientusers(id);
        if (response.data.data) {
          dispatch({
            type: roles.GET_ALL_ROLES,
            payload: response.data.data,
          });
          return;
        }
        // } else {
        //   dispatch({
        //     type: roles.GET_ALL_ROLES,
        //     payload: seasons,
        //   });
        //   return;
        // }
        throw new Error("Roles failed");
      } catch (error) {
        // TODO: dispatch error to errorReducer
        // to be implemented
      }
    };
  }

  static getAllStates(statess: statetype[]) {
    return async (dispatch: Dispatch) => {
      try {
        // if (statess.length === 0) {
        const response = await configService.getAllState();
        console.log(response);
        // const response = await UserService.getclientusers(id);
        if (response.data.data) {
          dispatch({
            type: states.GET_ALL_STATES,
            payload: response.data.data,
          });
          return;
        }
        // } else {
        //   dispatch({
        //     type: states.GET_ALL_STATES,
        //     payload: statess,
        //   });
        //   return;
        // }
        throw new Error("Roles failed");
      } catch (error) {
        // TODO: dispatch error to errorReducer
        // to be implemented
      }
    };
  }
  static getAllFarmingStage(farmStages: farmingStageType[]) {
    return async (dispatch: Dispatch) => {
      try {
        // if (farmStages.length === 0) {
        const response = await configService.getAllFarmingStage();
        console.log("HE", response);
        // const response = await UserService.getclientusers(id);
        if (response.data.data) {
          dispatch({
            type: farmingStage.GET_ALL_FARM_STAGE,
            payload: response.data.data,
          });
          return;
        }
        // } else {
        //   dispatch({
        //     type: farmingStage.GET_ALL_FARM_STAGE,
        //     payload: farmStages,
        //   });
        //   return;
        // }
        throw new Error("farm Stages failed");
      } catch (error) {
        // TODO: dispatch error to errorReducer
        // to be implemented
      }
    };
  }

  static getAllCropType(crops: cropTypeType[]) {
    return async (dispatch: Dispatch) => {
      try {
        // if (crops.length === 0) {
        const response = await configService.getAllCroptype();
        console.log("HE", response);
        // const response = await UserService.getclientusers(id);
        if (response.data.data) {
          dispatch({
            type: cropType.GET_ALL_CROP_TYPE,
            payload: response.data.data,
          });
          return;
        }
        // } else {
        //   dispatch({
        //     type: cropType.GET_ALL_CROP_TYPE,
        //     payload: crops,
        //   });
        //   return;
        // }
        throw new Error("Crop type failed");
      } catch (error) {
        // TODO: dispatch error to errorReducer
        // to be implemented
      }
    };
  }

  static getAllIncidence(incid: incidenceType[]) {
    return async (dispatch: Dispatch) => {
      try {
        // if (incid.length === 0) {
        const response = await configService.getAllincidence();
        // console.log("HE", response);
        // const response = await UserService.getclientusers(id);
        if (response.data.data) {
          dispatch({
            type: incidence.GET_ALL_INCIDENCE,
            payload: response.data.data,
          });
          return;
        }
        // } else {
        //   dispatch({
        //     type: incidence.GET_ALL_INCIDENCE,
        //     payload: incid,
        //   });
        //   return;
        // }
        throw new Error("Incidence failed");
      } catch (error) {
        // TODO: dispatch error to errorReducer
        // to be implemented
      }
    };
  }

  static getAllAssociation(assoc: associationType[]) {
    return async (dispatch: Dispatch) => {
      try {
        // if (assoc.length === 0) {
        const response = await configService.getAllAssociation();
        console.log("HE", response);
        // const response = await UserService.getclientusers(id);
        if (response.data.data) {
          dispatch({
            type: association.GET_ALL_ASSOCIATION,
            payload: response.data.data,
          });
          return;
        }
        // } else {
        //   dispatch({
        //     type: association.GET_ALL_ASSOCIATION,
        //     payload: assoc,
        //   });
        //   return;
        // }
        throw new Error("Association failed");
      } catch (error) {
        // TODO: dispatch error to errorReducer
        // to be implemented
      }
    };
  }
}
