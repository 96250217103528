export const seasonTypes = {
  GET_ALL_SEASONS: "GET_ALL_SEASONS",
};

export const roles = {
  GET_ALL_ROLES: "GET_ALL_ROLES",
};
export const states = {
  GET_ALL_STATES: "GET_ALL_STATES",
};
export const farmingStage = {
  GET_ALL_FARM_STAGE: "GET_ALL_FARM_STAGE",
};

export const cropType = {
  GET_ALL_CROP_TYPE: "GET_ALL_CROP_TYPE",
};

export const incidence = {
  GET_ALL_INCIDENCE: "GET_ALL_INCIDENCE",
};

export const association = {
  GET_ALL_ASSOCIATION: "GET_ALL_ASSOCIATION",
};

export const season = {
  GET_ALL_SEASON: "GET_ALL_SEASON",
};
export const seasonState = {
  GET_ALL_SEASONSTATE: "GET_ALL_SEASONSTATE",
};

export const seasonUpload = {
  GET_ALL_SEASONUPLOAD: "GET_ALL_SEASONUPLOAD",
};

export const werehouseUpload = {
  GET_ALL_WEREHOUSEUPLOAD: "GET_ALL_WEREHOUSEUPLOAD",
};

export const werehouseType = {
  GET_ALL_WAREHOUSE: "GET_ALL_WAREHOUSE",
};

export const eopType = {
  GET_ALL_EOP: "GET_ALL_EOP",
};
export const clusterType = {
  GET_ALL_CLUSTER: "GET_ALL_CLUSTER",
};
export const clusterStateType = {
  GET_ALL_STATE_CLUSTER: "GET_ALL_STATE_CLUSTER",
};

export const fieldType = {
  GET_ALL_FIELD: "GET_ALL_FIELD",
};
export const yieldType = {
  GET_ALL_YIELD: "GET_ALL_YIELD",
};

export const usersType = {
  GET_ALL_USERS: "GET_ALL_USERS",
  GET_ONE_USER: "GET_ONE_USER",
  GET_CLIENT_USER: "GET_CLIENT_USER",
  GET_ACTIVE_USERS: "GET_ACTIVE_USERS",
  GET_IN_ACTIVE_USERS: "GET_IN_ACTIVE_USERS",
  GET_ALL_ACTIVITY: "GET_ALL_ACTIVITY",
};
