import React from "react";
import { Button, Container, Table } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { useLoadingMessage } from "../../../../components/useLoadingMessage";
import { seasonThunk } from "../../../../redux/action/season/season.thunk";
import { ResponseEop } from "../../../../models/season.model";
import { RootState } from "../../../../redux/store/rootReducer";
import {
  useHistory,
  RouteComponentProps,
  Link,
  useLocation,
} from "react-router-dom";
import { PageBody } from "../../../../components/Loader";
import Currency from "../../../../components/Currency";
import { seasonService } from "../../../../Services";
import Layout from "../../../../components/Layout";

const All_eop = ({ match }: RouteComponentProps<{ id: string }>) => {
  const { isLoading, message, updateLoader } = useLoadingMessage();
  const dispatch = useDispatch();
  const [clusters, setClusters] = React.useState<any>([]);
  const [eop, setEop] = React.useState<any>([]);
  const [yields, setYields] = React.useState<any>([]);
  const alert = useAlert();
  const location = useLocation();
  const { status, seasonData } = location.state;

  const getclusters = async () => {
    updateLoader(true, "Fetching Data");
    const fields = await seasonService.getAllField(match.params.id);

    const bd = {
      pageNumber: 1,
      pageSize: 20,
      seasonId: match.params.id,
      clusterColumns: [...fields.data.data],
    };
    const response = await seasonService.getAllCluster(bd);
    setClusters(response.data.data);
    const responseYield = await seasonService.getAllYield(match.params.id);
    setYields(responseYield.data.data);
    const responseEop = await seasonService.getAllEop(match.params.id);
    setEop(responseEop.data.data);

    updateLoader(false, "");
  };

  let costOfEop = 0;
  let subTot = 0;
  eop.map((er: any) => {
    // costOfEop = clusters.hectares * er.eopSubTotal;
    subTot += er.eopSubTotal;
  });

  eop.map((er: any) => {
    costOfEop = clusters.hectares * subTot;
    // subTot += er.eopSubTotal;
  });

  React.useEffect(() => {
    getclusters();
  }, []);

  return (
    <PageBody isLoading={isLoading} message={message}>
      <Layout active="Seasons">
        <Container>
          <h5>
            Season: {seasonData.seasonType + " " + seasonData.associationName}
          </h5>
          <Link
            to={{
              pathname: `/eop/${match.params.id}`,
              state: {
                seasonData: seasonData,
                status: status,
              },
            }}
          >
            <Button
              className=" px-4 py-2 shadow btn-download  ml-4 mb-4"
              inline
            >
              Back
            </Button>
          </Link>
          {eop.map((eo: any, i: number) => (
            <Table hover responsive>
              <thead>
                <tr className="shadow">
                  <th>ITEM</th>
                  <th> QUANTITY</th>
                  <th> UNIT TYPE</th>
                  <th> UNIT PRICE </th>
                  <th> TOTAL </th>
                </tr>
              </thead>

              <tbody>
                {eo.eopResponseDetails.map((ep: any, i: number) => (
                  <tr>
                    <td>{ep.name}</td>
                    {/* <td>eo.eopResponseDetails.map </td> */}
                    <td>{ep.quantity}</td>
                    <td>{ep.quantityType}</td>
                    <td> ₦ {Currency(ep.unitPrice)}</td>
                    <th> ₦ {Currency(ep.itemTotal)}</th>
                  </tr>
                ))}

                <th colSpan={4}>Subtotal: ₦ {Currency(eo.eopSubTotal)}</th>
              </tbody>
            </Table>
          ))}
          <h5 className="font-bold">Total EOP: ₦ {Currency(subTot)}</h5>
          <div className="mt-5">
            <h4>Expected Yield</h4>
          </div>
          <Table hover responsive>
            <thead>
              <tr className="shadow">
                <th>No of bag</th>
                <th> Cost of bag</th>
                <th> TOTAL </th>
              </tr>
            </thead>

            <tbody>
              {yields[0] == null ? (
                <div className="text-center m-auto">
                  <h1>No record found</h1>
                </div>
              ) : (
                yields[0].eyResponseDetails.map((eo: any, i: number) => (
                  <tr>
                    <td> {eo.bags} </td>
                    <td>₦ {Currency(eo.costPerBag)}</td>
                    <th>₦ {Currency(eo.itemTotal)}</th>
                  </tr>
                ))
              )}
              <tr>
                <th>
                  Subtotal: ₦{" "}
                  {yields[0] == null ? 0 : Currency(yields[0].eySubTotal)}
                </th>
              </tr>
            </tbody>
          </Table>

          <Table className="mt-5">
            <thead>
              <tr>
                <th>PFi provided hectares</th>

                <th>Cost of EOP</th>
                <th> Expected Yield </th>
                <th>Profit</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{clusters.hectares}</td>

                <th> ₦ {Currency(costOfEop)}</th>
                <th>
                  {" "}
                  ₦{" "}
                  {Currency(
                    clusters.hectares *
                      (yields[0] == null ? 0 : yields[0].eySubTotal)
                  )}
                </th>
                <th>
                  ₦{" "}
                  {Currency(
                    clusters.hectares *
                      (yields[0] == null ? 0 : yields[0].eySubTotal) -
                      costOfEop
                  )}
                </th>
              </tr>
            </tbody>
          </Table>
        </Container>
      </Layout>
    </PageBody>
  );
};

export default All_eop;
