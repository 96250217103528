import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardText,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
  UncontrolledDropdown,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import classnames from "classnames";
import { Link } from "react-router-dom";
import AppBreadcrumb from "../../../../../components/layout/breadcrumb/Breadcrumb";
import { HiDotsHorizontal, HiDotsVertical } from "react-icons/hi";
import { useHistory, RouteComponentProps, useLocation } from "react-router-dom";
import { useLoadingMessage } from "../../../../../components/useLoadingMessage";
import { PageBody } from "../../../../../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store/rootReducer";
import { seasonThunk } from "../../../../../redux/action/season/season.thunk";
import { seasonService } from "../../../../../Services";
import { saveAs } from "file-saver";
import moment from "moment";
import Currency from "../../../../../components/Currency";
import Layout from "../../../../../components/Layout";
import AssignManagerModal from "../season_form/manager_modal";
const View_more = ({
  match,
}: RouteComponentProps<{ id: string; stateId: string }>) => {
  const [currentActiveTab, setCurrentActiveTab] = useState("1");
  const [activeSubTab, setActiveSubTab] = useState("1");

  const [stateClusters, setClusters] = React.useState<any>([]);
  const [answers, setAnswers] = React.useState<any>([]);
  const [submit, setSubmit] = React.useState<any>([]);
  const [warehouse, setWarehouse] = React.useState<any>([]);

  const { isLoading, message, updateLoader } = useLoadingMessage();
  const dispatch = useDispatch();
  const { fields }: any = useSelector((state: RootState) => state.fields);
  const location = useLocation();
  const { seasonData } = location.state || {};
  const [eop, setEop] = React.useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedWarehouseId, setSelectedWarehouseId] = useState<string | null>(
    null
  );
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);

  // const [farmstage, setEop] = React.useState<any>([]);

  const toggle = (tab: React.SetStateAction<string>) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };

  const toggleModal = (warehouseId?: string) => {
    setSelectedWarehouseId(warehouseId || "");
    setIsModalOpen(!isModalOpen);
  };

  const getWerehouseWithState = async (
    pageNumber: number = 1,
    pageSize: number = 20
  ) => {
    updateLoader(true, "Fetching Data");

    try {
      const response = await seasonService.getAllWarehouseWithState(
        match.params.stateId,
        pageNumber,
        pageSize
      );

      const warehouseData = response.data.data.warehouseData;
      if (Array.isArray(warehouseData.data)) {
        setWarehouse(warehouseData);
      } else {
        console.error("Expected warehouse data to be an array:", warehouseData);
      }

      updateLoader(false, "");
    } catch (error) {
      console.error("Error fetching warehouses:", error);
      updateLoader(false, "");
    }
  };

  const getclusters = async (query: number) => {
    updateLoader(true, "Fetching Data");
    const fields = await seasonService.getAllField(match.params.id);

    const bd = {
      pageNumber: query,
      pageSize: 20,
      seasonId: match.params.id,
      stateId: match.params.stateId,
      clusterColumns: [...fields.data.data],
    };
    const response = await seasonService.getAllStateCluster(bd);
    // const responses = await seasonService.getAllSeasonAnswer(
    //   match.params.stateId,
    //   match.params.id
    // );
    // // console.log(responses.data.response);
    // setAnswers(responses.data.response);
    setClusters(response.data.data);
    updateLoader(false, "");
  };

  const getanswers = async (query: number) => {
    updateLoader(true, "Fetching Data");

    const responses = await seasonService.getAllSeasonAnswerQuery(
      match.params.stateId,
      match.params.id,
      query
    );
    setAnswers(responses.data.response);
    updateLoader(false, "");
  };

  const getSubmit = async (query: number) => {
    updateLoader(true, "Fetching Data");

    const responses = await seasonService.getAllSubmission(
      match.params.stateId,
      match.params.id,
      query
    );
    console.log(responses);
    setSubmit(responses.data.response.data);
    console.log(submit);
    updateLoader(false, "");
  };

  const getclusterss = async () => {
    updateLoader(true, "Fetching Data");
    const fields = await seasonService.getAllField(match.params.id);

    const bd = {
      pageNumber: 1,
      pageSize: 20,
      seasonId: match.params.id,
      clusterColumns: [...fields.data.data],
    };
    // const response = await seasonService.getAllCluster(bd);
    // setClusters(response.data.data);
    const responseEop = await seasonService.getAllEop(match.params.id);
    setEop(responseEop.data.data);

    updateLoader(false, "");
  };
  const [search, setSearch] = React.useState("");
  const [search2, setSearch2] = React.useState("");
  const [search3, setSearch3] = React.useState("");

  // const stateClusterWare =
  //   stateClusters?.clusterData?.data &&
  //   stateClusters?.clusterData?.data.filter((farmingS: any) => {
  //     return (
  //       farmingS.warehouselocation
  //         .toLowerCase()
  //         .indexOf(search.toLowerCase()) !== -1 ||
  //       farmingS.warehousecontactpersonphonenumber
  //         .toLowerCase()
  //         .indexOf(search.toLowerCase()) !== -1
  //     );
  //   });

  const stateClusterss =
    stateClusters?.clusterData?.data &&
    stateClusters?.clusterData?.data.filter((farmingS: any) => {
      return (
        farmingS.clustername.toLowerCase().indexOf(search3.toLowerCase()) !==
          -1 ||
        farmingS.clusterfarmlocation
          .toLowerCase()
          .indexOf(search3.toLowerCase()) !== -1 ||
        farmingS.clusterheadfname
          .toLowerCase()
          .indexOf(search3.toLowerCase()) !== -1
      );
    });

  const answerss =
    answers.data &&
    answers.data.filter((farmingS: any) => {
      return (
        farmingS.question.toLowerCase().indexOf(search2.toLowerCase()) !== -1 ||
        farmingS.answer.toLowerCase().indexOf(search2.toLowerCase()) !== -1 ||
        farmingS.farmStageName.toLowerCase().indexOf(search2.toLowerCase()) !==
          -1 ||
        farmingS.lga.toLowerCase().indexOf(search2.toLowerCase()) !== -1 ||
        farmingS.dateModified.toLowerCase().indexOf(search2.toLowerCase()) !==
          -1 ||
        farmingS.submittedBy.toLowerCase().indexOf(search2.toLowerCase()) !== -1
      );
    });

  React.useEffect(() => {
    getclusters(1);
    getclusterss();
    getanswers(1);
    getSubmit(1);
    getWerehouseWithState(1);
  }, []);

  return (
    <PageBody isLoading={isLoading} message={message}>
      <Layout active="Seasons">
        <Container className="mb-4">
          <Row>
            <Col md={5}>
              <Link
                to={{
                  pathname: `/states/${match.params.id}`,
                  state: {
                    seasonData: seasonData,
                  },
                }}
              >
                <Button
                  className="btn-download px-4 py-2 shadow bg-or  ml-4"
                  inline
                >
                  Back
                </Button>
              </Link>
              <Button
                className="btn-create px-4 py-2 shadow bg-or ml-5"
                inline
                onClick={async () => {
                  const response: any = await seasonService.downloadSeasonState(
                    match.params.id,
                    match.params.stateId
                  );
                  saveAs(response, "ClusterStateRecord.csv");
                }}
              >
                Download Record
              </Button>
            </Col>
            <Col className="text-right">
              {" "}
              <h5>
                Season:{" "}
                {seasonData?.seasonType + " " + seasonData?.associationName}
              </h5>
            </Col>
          </Row>
        </Container>
        <Container>
          <Col sm={12} lg={12}>
            <div>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: currentActiveTab === "1",
                    })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Farm Verification
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: currentActiveTab === "2",
                    })}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Clusters
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: currentActiveTab === "3",
                    })}
                    onClick={() => {
                      toggle("3");
                    }}
                  >
                    Cultivation
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={classnames({
                      active: currentActiveTab === "5",
                    })}
                    onClick={() => {
                      toggle("5");
                    }}
                  >
                    Warehouse
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={classnames({
                      active: currentActiveTab === "4",
                    })}
                    onClick={() => {
                      toggle("4");
                    }}
                  >
                    Question/Answer
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={currentActiveTab}>
                <TabPane tabId="1">
                  <Row>
                    <Col>
                      <Card className="shadow border-0 mt-4">
                        <CardBody>
                          <Row>
                            <Col>
                              <CardText className="font-weight-bold">
                                Hectares
                              </CardText>
                              <CardText className="h3 font-weight-bold">
                                {stateClusters.hectares}
                              </CardText>
                            </Col>
                            <Col>
                              <Col>
                                <CardText className="font-weight-bold">
                                  Cluster
                                </CardText>
                                <CardText className="h3 font-weight-bold">
                                  {stateClusters.clusters}
                                </CardText>
                              </Col>
                            </Col>
                            <Col>
                              <Col>
                                <CardText className="font-weight-bold">
                                  Warehouse
                                </CardText>
                                <CardText className="h3 font-weight-bold">
                                  {stateClusters.warehouse}
                                </CardText>
                              </Col>
                            </Col>

                            <Col>
                              <Col>
                                <CardText className="font-weight-bold">
                                  No. Of Farmers
                                </CardText>
                                <CardText className="h3 font-weight-bold">
                                  0
                                </CardText>
                              </Col>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="8">
                      <Container className="mt-4">
                        <h6>Farm Verification</h6>
                        <Table borderless hover responsive striped>
                          <thead>
                            <tr>
                              <th>S/N</th>
                              <th> Cluster Name</th>
                              <th>L.G.A</th>
                              <th>PFI HECTARES</th>
                              <th>VERIFIED HECTARES</th>
                              <th>PLANTED</th>
                              <th>NO. OF FARMERS</th>
                            </tr>
                          </thead>
                          <tbody>
                            {stateClusters.clusterData &&
                            stateClusters.clusterData.data.length < 1 ? (
                              <div className="text-center">
                                <h5>No record found</h5>
                              </div>
                            ) : (
                              stateClusters.clusterData &&
                              stateClusters.clusterData.data.map(
                                (cl: any, i: number) => (
                                  <tr>
                                    <th scope="row">
                                      {(stateClusters.clusterData?.meta
                                        .pageNumber -
                                        1) *
                                        20 +
                                        (i + 1)}
                                    </th>
                                    <td>{cl.clustername}</td>
                                    <td>{cl.clusterlga}</td>
                                    <td> {cl.numberofhectares} </td>
                                    <td> {cl.verifiedfarmsize} </td>
                                    <td> {cl.isplanted} </td>
                                    <td> </td>
                                  </tr>
                                )
                              )
                            )}
                          </tbody>
                        </Table>

                        <div className="">
                          <Pagination aria-label="Page navigation">
                            <PaginationItem
                              disabled={
                                stateClusters.clusterData?.meta.pageNumber ==
                                stateClusters.clusterData?.meta.firstPage
                                  ? true
                                  : false
                              }
                            >
                              <PaginationLink
                                first
                                onClick={() =>
                                  getclusters(
                                    stateClusters.clusterData?.meta.pageNumber -
                                      1
                                  )
                                }
                              />
                            </PaginationItem>
                            {Array.from({
                              length:
                                stateClusters.clusterData?.meta.pageNumber == 1
                                  ? stateClusters.clusterData?.meta.lastPage
                                  : stateClusters.clusterData?.meta.lastPage +
                                    1 -
                                    stateClusters.clusterData?.meta.pageNumber,
                            })
                              .slice(
                                stateClusters.clusterData?.meta.pageNumber > 5
                                  ? stateClusters.clusterData?.meta.pageNumber
                                  : 0,
                                stateClusters.clusterData?.meta.pageNumber >=
                                  5 &&
                                  stateClusters.clusterData?.meta.lastPage >
                                    stateClusters.clusterData?.meta.pageNumber +
                                      5
                                  ? stateClusters.clusterData?.meta.pageNumber +
                                      5
                                  : 5
                              )
                              .map((u: any, i: any) => (
                                <PaginationItem
                                  active={
                                    stateClusters.clusterData?.meta
                                      .pageNumber ==
                                    i +
                                      stateClusters.clusterData?.meta.pageNumber
                                      ? true
                                      : false
                                  }
                                >
                                  <PaginationLink
                                    onClick={() =>
                                      getclusters(
                                        i +
                                          stateClusters.clusterData?.meta
                                            .pageNumber
                                      )
                                    }
                                  >
                                    {i +
                                      stateClusters.clusterData?.meta
                                        .pageNumber}
                                  </PaginationLink>
                                </PaginationItem>
                              ))}
                            {stateClusters.clusterData?.meta.lastPage > 10 ? (
                              <PaginationItem>
                                <PaginationLink>...</PaginationLink>
                              </PaginationItem>
                            ) : null}

                            {stateClusters.clusterData?.meta.lastPage > 10 &&
                            stateClusters.clusterData?.meta.lastPage - 5 >
                              stateClusters.clusterData?.meta.pageNumber
                              ? Array.from({
                                  length:
                                    stateClusters.clusterData?.meta.lastPage,
                                })
                                  .slice(
                                    stateClusters.clusterData?.meta.lastPage -
                                      5,
                                    stateClusters.clusterData?.meta.lastPage
                                  )
                                  .map((u: any, i: any) => (
                                    <PaginationItem
                                      active={
                                        stateClusters.clusterData?.meta
                                          .pageNumber ==
                                        stateClusters.clusterData?.meta
                                          .lastPage -
                                          5 +
                                          i
                                          ? true
                                          : false
                                      }
                                    >
                                      <PaginationLink
                                        onClick={() =>
                                          getclusters(
                                            stateClusters.clusterData?.meta
                                              .lastPage -
                                              5 +
                                              i
                                          )
                                        }
                                      >
                                        {stateClusters.clusterData?.meta
                                          .lastPage -
                                          5 +
                                          i}
                                      </PaginationLink>
                                    </PaginationItem>
                                  ))
                              : null}
                            <PaginationItem
                              disabled={
                                stateClusters.clusterData?.meta.pageNumber ==
                                stateClusters.clusterData?.meta.lastPage
                                  ? true
                                  : false
                              }
                            >
                              <PaginationLink
                                last
                                onClick={() =>
                                  getclusters(
                                    stateClusters.clusterData?.meta.nextPage
                                  )
                                }
                              />
                            </PaginationItem>
                          </Pagination>
                        </div>
                      </Container>
                    </Col>
                    <Col className="mt-4">
                      <Card className="border-0 shadow">
                        <CardBody>
                          <div>
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3939.740726062934!2d7.469159414787075!3d9.087367593480412!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104e0ba9c728f379%3A0x5b91b590d55fb00e!2sNeedle%20Technology%20Ltd!5e0!3m2!1sen!2sng!4v1636290686127!5m2!1sen!2sng"
                              width="100%"
                              height="400"
                              className="border-0"
                            ></iframe>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col sm="12">
                      <Container className="mt-4">
                        <Row className="mb-4">
                          <Col md={9}>
                            <span className="ml-5">
                              <Input
                                placeholder="search"
                                type="search"
                                className="form-search"
                                name="search3"
                                value={search3}
                                onChange={(e) => setSearch3(e.target.value)}
                              />
                            </span>
                          </Col>
                          <Col md={3} className="text-right"></Col>
                        </Row>
                        <Table borderless hover responsive striped>
                          <thead>
                            <tr>
                              <th>S/N</th>
                              <th> CLUSTER</th>
                              <th>LOCATION</th>
                              <th> L.G.A</th>
                              <th>No. of HECTARES</th>
                              {/* <th> NO. OF FARMERS</th> */}
                              <th>CLUSTER HEAD</th>
                              <th> PHONE</th>
                              {/* <th>LOG.</th>
                            <th> LAT.</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {stateClusterss && stateClusterss.length < 1 ? (
                              <div className="text-center">
                                <h5>No record found</h5>
                              </div>
                            ) : (
                              stateClusterss &&
                              stateClusterss.map((cl: any, i: number) => (
                                <tr>
                                  <th scope="row">
                                    {" "}
                                    {(stateClusters.clusterData?.meta
                                      .pageNumber -
                                      1) *
                                      20 +
                                      (i + 1)}{" "}
                                  </th>
                                  <td> {cl.clustername} </td>
                                  <td> {cl.clusterfarmlocation} </td>
                                  <td> {cl.clusterlga} </td>
                                  <td> {cl.numberofhectares}</td>

                                  <td>
                                    {" "}
                                    {cl.clusterheadfname +
                                      " " +
                                      cl.clusterheadsurname}{" "}
                                  </td>
                                  <td> {cl.clusterheadcontact} </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </Table>

                        <div className="">
                          <Pagination aria-label="Page navigation">
                            <PaginationItem
                              disabled={
                                stateClusters.clusterData?.meta.pageNumber ==
                                stateClusters.clusterData?.meta.firstPage
                                  ? true
                                  : false
                              }
                            >
                              <PaginationLink
                                first
                                onClick={() =>
                                  getclusters(
                                    stateClusters.clusterData?.meta.pageNumber -
                                      1
                                  )
                                }
                              />
                            </PaginationItem>
                            {Array.from({
                              length:
                                stateClusters.clusterData?.meta.pageNumber == 1
                                  ? stateClusters.clusterData?.meta.lastPage
                                  : stateClusters.clusterData?.meta.lastPage +
                                    1 -
                                    stateClusters.clusterData?.meta.pageNumber,
                            })
                              .slice(
                                stateClusters.clusterData?.meta.pageNumber > 5
                                  ? stateClusters.clusterData?.meta.pageNumber
                                  : 0,
                                stateClusters.clusterData?.meta.pageNumber >
                                  5 &&
                                  stateClusters.clusterData?.meta.pageNumber <
                                    stateClusters.clusterData?.meta.lastPage - 5
                                  ? stateClusters.clusterData?.meta.pageNumber +
                                      5
                                  : 5
                              )
                              .map((u: any, i: any) => (
                                <PaginationItem
                                  active={
                                    stateClusters.clusterData?.meta
                                      .pageNumber ==
                                    i +
                                      stateClusters.clusterData?.meta.pageNumber
                                      ? true
                                      : false
                                  }
                                >
                                  <PaginationLink
                                    onClick={() =>
                                      getclusters(
                                        i +
                                          stateClusters.clusterData?.meta
                                            .pageNumber
                                      )
                                    }
                                  >
                                    {i +
                                      stateClusters.clusterData?.meta
                                        .pageNumber}
                                  </PaginationLink>
                                </PaginationItem>
                              ))}
                            {stateClusters.clusterData?.meta.lastPage > 10 ? (
                              <PaginationItem>
                                <PaginationLink>...</PaginationLink>
                              </PaginationItem>
                            ) : null}

                            {stateClusters.clusterData?.meta.lastPage > 10 &&
                            stateClusters.clusterData?.meta.lastPage - 5 >
                              stateClusters.clusterData?.meta.pageNumber
                              ? Array.from({
                                  length:
                                    stateClusters.clusterData?.meta.lastPage,
                                })
                                  .slice(
                                    stateClusters.clusterData?.meta.lastPage -
                                      5,
                                    stateClusters.clusterData?.meta.lastPage
                                  )
                                  .map((u: any, i: any) => (
                                    <PaginationItem
                                      active={
                                        stateClusters.clusterData?.meta
                                          .pageNumber ==
                                        stateClusters.clusterData?.meta
                                          .lastPage -
                                          5 +
                                          i
                                          ? true
                                          : false
                                      }
                                    >
                                      <PaginationLink
                                        onClick={() =>
                                          getclusters(
                                            stateClusters.clusterData?.meta
                                              .lastPage -
                                              5 +
                                              i
                                          )
                                        }
                                      >
                                        {stateClusters.clusterData?.meta
                                          .lastPage -
                                          5 +
                                          i}
                                      </PaginationLink>
                                    </PaginationItem>
                                  ))
                              : null}
                            <PaginationItem
                              disabled={
                                stateClusters.clusterData?.meta.pageNumber ==
                                stateClusters.clusterData?.meta.lastPage
                                  ? true
                                  : false
                              }
                            >
                              <PaginationLink
                                last
                                onClick={() =>
                                  getclusters(
                                    stateClusters.clusterData?.meta.nextPage
                                  )
                                }
                              />
                            </PaginationItem>
                          </Pagination>
                        </div>
                      </Container>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="3">
                  <Row>
                    <Col sm="12">
                      <Container className="mt-4">
                        {/*                       
                        <h6 className="mt-5">Cultivation Report</h6>
                        <Table borderless hover responsive striped>
                          <thead>
                            <tr>
                              <th>S/N</th>
                              <th> BANK PROVIDED HECTARES</th>
                              <th> VERIFIED HECTARES</th>
                              <th>PLANTED HECTARES</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">1</th>
                              <td>45,000</td>
                              <td>12,000</td>
                              <td>10,000</td>
                            </tr>
                          </tbody>
                        </Table> */}

                        <h6 className="mt-5">Inventory</h6>

                        {eop
                          // .filter((eop: any) => eopCategory == "Input")
                          .map((eo: any, i: number) => (
                            <Table hover responsive>
                              <thead>
                                <tr className="shadow">
                                  <th>S/N</th>
                                  <th> INPUT</th>
                                  <th> EXPECTED QUANTITY</th>
                                  <th>WH QUANTITY</th>
                                  <th>DIFFERENCE</th>
                                </tr>
                              </thead>

                              <tbody>
                                {eo.eopResponseDetails.map(
                                  (ep: any, i: number) => (
                                    <tr>
                                      <td> {i + 1} </td>
                                      <td>{ep.name}</td>
                                      <td>{ep.quantity}</td>

                                      <td></td>
                                      <td></td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </Table>
                          ))}

                        <Row>
                          <Col md={4}>
                            <h6 className="fw-bold ">Farming Stage Report</h6>
                          </Col>
                          <Col>
                            <Row>
                              <Col>
                                <div className="  bg-secondary farm-status"></div>
                                <small> Not Started</small>
                              </Col>
                              <Col>
                                <div className="  bg-success farm-status"></div>
                                <small className=""> Done</small>
                              </Col>
                              <Col>
                                <div className="  bg-warning farm-status"></div>
                                <small>Ongoing</small>
                              </Col>
                              <Col>
                                <div className="  bg-danger farm-status ml-3"></div>
                                <small>Not Done</small>
                              </Col>
                              <Col>
                                <div className="  bg-primary farm-status ml-3"></div>
                                <small> Done Late</small>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Table borderless hover responsive striped>
                          <thead>
                            <tr>
                              <th>Cluster Name</th>
                              {/* {submit?.map((fs: any, i: any) => */}
                              {/* <th key={i}>{fs.clusterName}</th> */}
                              {submit[0]?.submissionData?.map(
                                (f: any, i: any) => (
                                  // <td key={i}>{fs.clusterName}</td>;
                                  <th key={i} className="fs-6">
                                    <small className="fw-bold">
                                      <strong> {f?.farmStage}</strong>
                                    </small>
                                  </th>
                                )
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {submit?.map(
                              (fs: any, i: any) => (
                                // fs?.submissionData?.map((f: any, j: any) => (
                                <tr key={i} className="fs-6">
                                  <td>{fs.clusterName}</td>{" "}
                                  {/* {submit?.map((fs: any, i: any) => */}
                                  {fs?.submissionData.map((f: any, i: any) => (
                                    <td key={i}>
                                      {f.colorCode.toLowerCase() ==
                                      "not started" ? (
                                        <div className="bg-secondary farm-status"></div>
                                      ) : null}

                                      {f.colorCode.toLowerCase() ==
                                      "on going" ? (
                                        <div className="bg-warning farm-status"></div>
                                      ) : null}

                                      {f.colorCode.toLowerCase() == "done" ? (
                                        <div className="bg-success farm-status"></div>
                                      ) : null}

                                      {f.colorCode.toLowerCase() ==
                                      "not done" ? (
                                        <div className="bg-danger farm-status"></div>
                                      ) : null}
                                      {f.colorCode.toLowerCase() ==
                                      "done late" ? (
                                        <div className="bg-primary farm-status"></div>
                                      ) : null}
                                    </td>
                                  ))}
                                  {/* )} */}
                                </tr>
                              )
                              // ))
                            )}
                          </tbody>
                        </Table>
                      </Container>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="4">
                  <Row>
                    <Col sm="12">
                      <Container className="mt-4">
                        <Row>
                          <Col md={9} className="mb-5">
                            <span className="ml-5">
                              <Input
                                placeholder="search"
                                type="search"
                                className="form-search"
                                name="search2"
                                value={search2}
                                onChange={(e) => setSearch2(e.target.value)}
                              />
                            </span>
                          </Col>
                          <Col md={3} className="text-right"></Col>
                        </Row>
                        <Table borderless hover responsive striped>
                          <thead>
                            <tr>
                              <th>S/N</th>
                              <th>Farm Stage</th>
                              <th>Question</th>
                              <th>Answer</th>

                              <th>LGA</th>

                              <th>Date</th>
                              <th>Enumerator</th>
                            </tr>
                          </thead>
                          <tbody>
                            {answerss && answerss.length < 1 ? (
                              <div className="text-center">
                                <h5>No record found</h5>
                              </div>
                            ) : (
                              answerss &&
                              answerss.map((cl: any, i: number) => (
                                <tr>
                                  <th scope="row">
                                    {(answers?.meta.pageNumber - 1) * 20 +
                                      (i + 1)}
                                  </th>
                                  <td>{cl.farmStageName}</td>
                                  <td>{cl.question}</td>
                                  <td>{cl.answer}</td>

                                  <td> {cl.lga} </td>

                                  <td>
                                    {" "}
                                    {moment(cl.dateModified).format(
                                      "llll"
                                    )}{" "}
                                  </td>
                                  <td> {cl.submittedBy} </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </Table>
                        <div className="">
                          <Pagination aria-label="Page navigation">
                            <PaginationItem
                              disabled={
                                answers?.meta?.pageNumber ==
                                answers?.meta?.firstPage
                                  ? true
                                  : false
                              }
                            >
                              <PaginationLink
                                first
                                onClick={() =>
                                  getanswers(answers?.meta?.pageNumber - 1)
                                }
                              />
                            </PaginationItem>
                            {Array.from({
                              length:
                                answers?.meta?.pageNumber == 1
                                  ? answers?.meta?.lastPage
                                  : answers?.meta?.lastPage +
                                    1 -
                                    answers?.meta?.pageNumber,
                            }).map((u: any, i: any) => (
                              <PaginationItem
                                active={
                                  answers?.meta?.pageNumber ==
                                  i + answers?.meta?.pageNumber
                                    ? true
                                    : false
                                }
                              >
                                <PaginationLink
                                  onClick={() =>
                                    getanswers(i + answers?.meta?.pageNumber)
                                  }
                                >
                                  {i + answers?.meta?.pageNumber}
                                </PaginationLink>
                              </PaginationItem>
                            ))}

                            <PaginationItem
                              disabled={
                                answers?.meta?.totalPages ==
                                answers?.meta?.pageNumber
                                  ? true
                                  : false
                              }
                            >
                              <PaginationLink
                                last
                                onClick={() =>
                                  getanswers(answers?.meta?.nextPage)
                                }
                              />
                            </PaginationItem>
                          </Pagination>
                        </div>
                      </Container>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="5">
                  <Row>
                    <Col sm="12">
                      <Container className="mt-4">
                        <Row>
                          <Col md={9} className="mb-5">
                            <span className="ml-5">
                              <Input
                                placeholder="search"
                                type="search"
                                className="form-search"
                                name="search"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                              />
                            </span>
                          </Col>
                          <Col md={3} className="text-right"></Col>
                        </Row>
                        <Table borderless hover responsive striped>
                          <thead>
                            <tr>
                              <th>S/N</th>
                              <th>NAME</th>
                              <th>STATE</th>
                              <th>L.G.A</th>
                              <th>ADDRESS</th>
                              <th>MANAGER</th>
                            </tr>
                          </thead>
                          <tbody>
                            {warehouse.length === 0 ? (
                              <tr>
                                <td className="text-center">
                                  <h5>No record found</h5>
                                </td>
                              </tr>
                            ) : (
                              warehouse?.data.map((item: any, i: number) => (
                                <tr>
                                  <th scope="row">
                                    {" "}
                                    {(warehouse?.meta?.pageNumber - 1) * 20 +
                                      (i + 1)}{" "}
                                  </th>
                                  <td>{item["werehouse name"]}</td>
                                  <td>{item["warehouse state"]}</td>
                                  <td>{item.lga}</td>
                                  <td>{item.address}</td>
                                  <td>{item.managerName}</td>
                                  <td>
                                    <div className="justify-content-center">
                                      <UncontrolledDropdown className="m-drop">
                                        <DropdownToggle className="m-drop bg-transparent border-0 text-dark font-weight-bold">
                                          <HiDotsVertical />
                                        </DropdownToggle>
                                        <DropdownMenu container="body">
                                          <DropdownItem
                                            onClick={() =>
                                              toggleModal(item.uniqueId)
                                            }
                                          >
                                            Assign Manager
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </Table>

                        <div className="">
                          <Pagination aria-label="Page navigation">
                            <PaginationItem
                              disabled={
                                warehouse?.meta?.pageNumber ==
                                warehouse?.meta?.firstPage
                                  ? true
                                  : false
                              }
                            >
                              <PaginationLink
                                first
                                onClick={() =>
                                  getWerehouseWithState(
                                    warehouse?.meta?.pageNumber - 2
                                  )
                                }
                              />
                            </PaginationItem>
                            <PaginationItem
                              disabled={
                                warehouse?.meta?.pageNumber ==
                                warehouse?.meta?.firstPage
                                  ? true
                                  : false
                              }
                            >
                              <PaginationLink
                                previous
                                onClick={() =>
                                  getWerehouseWithState(
                                    warehouse?.meta?.pageNumber - 1
                                  )
                                }
                              />
                            </PaginationItem>

                            {Array.from({
                              length: warehouse?.meta?.lastPage,
                            })
                              .slice(
                                warehouse?.meta?.pageNumber > 5
                                  ? warehouse?.meta?.pageNumber
                                  : 0,
                                warehouse?.meta?.pageNumber > 5 ||
                                  warehouse?.meta?.pageNumber <
                                    warehouse?.meta?.lastPage - 5
                                  ? warehouse?.meta?.pageNumber + 5
                                  : 5
                              )
                              .map((u: any, i: any) => (
                                <PaginationItem
                                  active={
                                    warehouse?.meta?.pageNumber ==
                                    i + warehouse?.meta?.pageNumber
                                      ? true
                                      : false
                                  }
                                >
                                  <PaginationLink
                                    onClick={() =>
                                      getWerehouseWithState(
                                        i + warehouse?.meta?.pageNumber
                                      )
                                    }
                                  >
                                    {i + warehouse?.meta?.pageNumber}
                                  </PaginationLink>
                                </PaginationItem>
                              ))}
                            {warehouse?.meta?.lastPage > 10 ? (
                              <PaginationItem>
                                <PaginationLink>...</PaginationLink>
                              </PaginationItem>
                            ) : null}

                            {warehouse?.meta?.lastPage > 10 &&
                            warehouse?.meta?.lastPage - 5 >
                              warehouse?.meta?.pageNumber
                              ? Array.from({
                                  length: warehouse?.meta?.lastPage,
                                })
                                  .slice(
                                    warehouse?.meta?.lastPage - 5,
                                    warehouse?.meta?.lastPage
                                  )
                                  .map((u: any, i: any) => (
                                    <PaginationItem
                                      active={
                                        warehouse?.meta?.pageNumber ==
                                        warehouse?.meta?.lastPage - 5 + i
                                          ? true
                                          : false
                                      }
                                    >
                                      <PaginationLink
                                        onClick={() =>
                                          getWerehouseWithState(
                                            warehouse?.meta?.lastPage - 5 + i
                                          )
                                        }
                                      >
                                        {warehouse?.meta?.lastPage - 5 + i}
                                      </PaginationLink>
                                    </PaginationItem>
                                  ))
                              : null}
                            <PaginationItem
                              disabled={
                                warehouse?.meta?.pageNumber ==
                                warehouse?.meta?.lastPage
                                  ? true
                                  : false
                              }
                            >
                              <PaginationLink
                                next
                                onClick={() =>
                                  getWerehouseWithState(
                                    warehouse?.meta?.nextPage
                                  )
                                }
                              />
                            </PaginationItem>
                            <PaginationItem
                              disabled={
                                warehouse?.meta?.pageNumber ==
                                warehouse?.meta?.lastPage
                                  ? true
                                  : false
                              }
                            >
                              <PaginationLink
                                last
                                onClick={() =>
                                  getWerehouseWithState(
                                    warehouse?.meta?.nextPage + 1
                                  )
                                }
                              />
                            </PaginationItem>
                          </Pagination>
                        </div>

                        <AssignManagerModal
                          isOpen={isModalOpen}
                          toggle={toggleModal}
                          uniqueId={selectedWarehouseId ?? ""}
                          refresh={getWerehouseWithState}
                        />
                      </Container>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </div>
          </Col>
        </Container>
      </Layout>
    </PageBody>
  );
};

export default View_more;
